import { FieldErrors } from "react-hook-form";

interface IInputError {
  errors: FieldErrors;
  id: string;
  errorsShow: boolean;
}

const InputError = ({ errors, id, errorsShow }: IInputError) => {
  const isSingleErr = errors[id]?.type && !errors[id]?.types;
  const errorMessageSingle = errors[id]?.message as string;
  const theresErrorsBeyond = errors[id]?.types;
  let restOfErrors = Object.values(errors[id]?.types || {})
    .flat(Infinity)
    .map((elem) => <p>{elem}</p>);

  return (
    errorsShow && (
      <div className="text-xs text-red-500">
        {isSingleErr ? (
          <p>{errorMessageSingle}</p>
        ) : (
          theresErrorsBeyond && restOfErrors
        )}
      </div>
    )
  );
};

export default InputError;
