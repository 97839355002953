import { z } from "zod";

export const passwordSchema = z
  .string()
  .min(8, "Password must be at least 8 characters long")
  .max(20, "Password must be at most 20 characters long")
  .refine((pass) => /(?=.*[0-9])/.test(pass), "Password should contain a digit")
  .refine(
    (pass) => /(?=.*[a-z])/.test(pass),
    "Password should contain at least one lowercase character",
  )
  .refine(
    (pass) => /(?=.*[A-Z])/.test(pass),
    "Password should contain at least one uppercase character",
  )
  .refine(
    (pass) => /(?=.*\W)/.test(pass),
    "Password should contain one special character",
  );

export const passwordUnionAccDetails = z.object({
  password: z.string().min(1, "Current password is required"),
  newPassword: z.union([passwordSchema, z.string().nullish()]),
});

export const passwordSchemaAccountDetails = z.union([
  z
    .string()
    .min(8, "Password must be at least 8 characters long")
    .max(20, "Password must be at most 20 characters long")
    .refine(
      (pass) => /(?=.*[0-9])/.test(pass),
      "Password should contain a digit",
    )
    .refine(
      (pass) => /(?=.*[a-z])/.test(pass),
      "Password should contain at least one lowercase character",
    )
    .refine(
      (pass) => /(?=.*[A-Z])/.test(pass),
      "Password should contain at least one uppercase character",
    )
    .refine(
      (pass) => /(?=.*\W)/.test(pass),
      "Password should contain one special character",
    ),
  z.string().nullish(),
]);

export const passwordRestraints = z
  .object({
    password: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export const passwordChangeRestraints = z
  .object({
    password: passwordSchema,
    confirmPassword: z.string(),
    email: z.string().email(),
    token: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });
