export const googleSvg = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8 10.4082C18.8 9.7582 18.7417 9.1332 18.6333 8.5332H10V12.0832H14.9333C14.7167 13.2249 14.0667 14.1915 13.0917 14.8415V17.1499H16.0667C17.8 15.5499 18.8 13.1999 18.8 10.4082Z"
        fill="#4285F4"
      />
      <path
        d="M9.99998 19.3669C12.475 19.3669 14.55 18.5502 16.0667 17.1502L13.0917 14.8419C12.275 15.3919 11.2333 15.7252 9.99998 15.7252C7.61665 15.7252 5.59165 14.1169 4.86665 11.9502H1.81665V14.3169C3.32498 17.3085 6.41665 19.3669 9.99998 19.3669Z"
        fill="#34A853"
      />
      <path
        d="M4.86659 11.9418C4.68325 11.3918 4.57492 10.8085 4.57492 10.2001C4.57492 9.5918 4.68325 9.00846 4.86659 8.45846V6.0918H1.81659C1.19159 7.32513 0.833252 8.7168 0.833252 10.2001C0.833252 11.6835 1.19159 13.0751 1.81659 14.3085L4.19159 12.4585L4.86659 11.9418Z"
        fill="#FBBC05"
      />
      <path
        d="M9.99998 4.6832C11.35 4.6832 12.55 5.14987 13.5083 6.04987L16.1333 3.42487C14.5417 1.94154 12.475 1.0332 9.99998 1.0332C6.41665 1.0332 3.32498 3.09154 1.81665 6.09154L4.86665 8.4582C5.59165 6.29154 7.61665 4.6832 9.99998 4.6832Z"
        fill="#EA4335"
      />
    </svg>
  );
};
